import React from 'react';
import Megumin from './components/Megumin';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div className="App">
      <Megumin />
    </div>
  );
}

export default App;