// Megumin.js

import React, { useState } from 'react';
import './Megumin.css'; // Import the CSS file

const MeguminCTO = () => {
  const [caText] = useState('nTdT3vCJtaW7KSheSfAA9a2SeCTKa8vQUL8NwNSpump');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(caText);
    alert('Copied to clipboard!');
  };

  return (
    <div className="container">     
   <div className="floating-text text1">Explosion Magic is the ultimate power!</div>
      <div className="floating-text text2">爆発は究極の力です！</div> {/* "Explosion is the ultimate power!" in Japanese */}
      <div className="floating-text text3">Megumin, the Arch Wizard of the Crimson Magic Clan</div>

      <div className="marquee-container">
        <div className="marquee">
          $EXPLOSION $EXPLOSION $EXPLOSION $EXPLOSION $EXPLOSION $EXPLOSION
        </div>
      </div>
      <div className="social-icons">
        <a href="https://x.com/meguminonsol" target="_blank" rel="noopener noreferrer">
          <svg className="social-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
          </svg>
        </a>
        <a href="https://pump.fun/nTdT3vCJtaW7KSheSfAA9a2SeCTKa8vQUL8NwNSpump" target="_blank" rel="noopener noreferrer">
          <img className="social-icon" src="https://pump.fun/_next/image?url=%2Flogo.png&w=64&q=75" alt="Pump.fun logo" width="40" height="40" />
        </a>
      </div>

      <div className="ca-field">
        CA: {caText}
        <i className="fas fa-copy copy-icon" onClick={copyToClipboard}></i>
      </div>

      <div className="mascot-container">
        <img src="animation.gif" alt="Cute mascot" className="mascot floating" />
      </div>

      {/* Add temporary images on the page */}
      <img src="https://png.pngtree.com/png-clipart/20220821/ourmid/pngtree-bento-box-bekal-png-image_6120186.png" alt="Temp 1" className="temp-image top-left" />
      <img src="https://clipart-library.com/images/dT9ddegT7.png" alt="Temp 2" className="temp-image top-right" />
      <img src="https://i.imgur.com/g5kqGiV.png" alt="Temp 3" className="temp-image bottom-left" />
      <img src="https://grift.shop/cdn/shop/products/embroidered-patches-white-heart---3.1x2.8-front-638e749668c7a_grande.png?v=1670280384" alt="Temp 4" className="temp-image bottom-right" />

    </div>
  );
};

export default MeguminCTO;